<template>
  <div :class="this.$vuetify.breakpoint.smAndUp ? 'myClass' : 'myXs'">
    <v-btn
      @click="imgClick"
      elevation="4"
      height="100%"
      width="100%"
      :disabled="disable"
      :style="
        'border: 1px solid black;' +
        'background-image: url(' +
        profilePic +
        '); background-position: 50% 0; background-size: cover; background-color: rgba(0, 0, 0, 0)'
      "
    >
    </v-btn>

    <v-file-input
      v-model="tempImage"
      name="file"
      ref="avatarUpload"
      class="pa-0 ma-0 mt-1"
      :label="$vuetify.lang.t('$vuetify.avatar')"
      prepend-icon=""
      :hide-input="true"
      accept="image/jpeg, image/png"
    />
  </div>
</template>

<script>
import serverConfig from "@/utils/serverConfig";

export default {
  name: "image-upload",
  props: {
    image: File,
    avatar: String,
    disable: Boolean,
    defaultImg: {
      type: String,
      default: function () {
        return serverConfig.blank_img;
      },
    },
  },
  methods: {
    imgClick() {
      this.$refs.avatarUpload.$refs.input.click();
    },
  },
  computed: {
    profilePic: function () {
      return this.image == undefined
        ? this.avatar != ""
          ? serverConfig.img_url + this.avatar
          : this.defaultImg
        : URL.createObjectURL(this.image);
    },
    tempImage: {
      get: function () {
        return this.image;
      },
      set: function (value) {
        value.type == "image/jpeg" || value.type == "image/png"
          ? this.$emit("update:image", value)
          : this.$toast.error(this.$vuetify.lang.t("$vuetify.uploadImgOnly"));
      },
    },
  },
};
</script>

<style>
.myClass {
  width: 100%;
  height: 100%;
}
.myXs {
  width: 30rem;
  height: 25rem;
}
</style>